.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
	background-color: #060606 !important;
}

.rmdp-month-picker {
	background-color: #060606 !important;
}

.rmdp-container {
	width: 100%;
}
